/* eslint-disable */
/* eslint-disable prefer-template */
import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { User, List } from "react-feather";
import { formatDistanceToNow, differenceInDays } from "date-fns";
import SnackbarWrapper from "../../../../components/SnackbarWrapper";
import { StyledLink, CustomDatatableV2, ReportLink } from "../../styles";
import RolesTooltip from "./RolesTooltip";
import TableTitle from "../../../../components/Table/TableTitle";
import DeleteButtonForTables from "../../../../components/Table/DeleteButtonForTables";
import ConfirmModalSmall from "../../../../components/Modals/ConfirmModalSmall";
import { Modal, useMediaQuery, Button } from "@material-ui/core";
import API from "../../../../axios/instances/API";
import CustomSearchBarForTables from "../../../../components/Table/CustomSearchBarForTables";
import CustomDataTableIcons from "../../../../components/Table/CustomDataTableIcons";
import BulkDelete from "./BulkDelete";
import DateTooltip from "../../../../components/Table/DateTooltip";
import MultipleLocationPopperV2 from "./MultipleLocationPopperV2";

const DaysIndicator = styled.div`
  border-radius: 4px;
  width: 8px;
  height: 8px;
  margin-right: 8px;
`;

const getDifferenceInDaysIndicator = (date) => {
  if (!date) return <DaysIndicator style={{ backgroundColor: "tomato" }} />;

  const TODAY = new Date();
  const diff = differenceInDays(TODAY, date);

  if (diff < 31)
    return <DaysIndicator style={{ backgroundColor: "#03ac13" }} />;
  if (diff < 121)
    return <DaysIndicator style={{ backgroundColor: "#74c69d" }} />;
  if (diff < 352)
    return <DaysIndicator style={{ backgroundColor: "orange" }} />;

  return <DaysIndicator style={{ backgroundColor: "darkorange" }} />;
};

const TeamDataTable = ({ team, mutate, isManager, readOnly }) => {
  const [snackbarText, setSnackbarText] = useState("");
  const [deleteInfo, setDeleteInfo] = useState(null);
  const [bulkDeleteOpen, setBulkDeleteOpen] = useState(false);
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const handleDeleteSingleTeamMember = async () => {
    try {
      await API.delete(`/dashboard/teams/details/?id=${deleteInfo?.id}`);
      await mutate();
      setSnackbarText(`Deleted team member: ${deleteInfo?.name} success`);
      return setDeleteInfo(null);
    } catch (error) {
      setSnackbarText("Failed to delete team memeber error");
      return setDeleteInfo(null);
    }
  };

  const options = {
    filterType: "textField",
    textLabels: {
      body: {
        noMatch:
          'No team member(s) on your team yet. Click the "Invite Team Member" button to add team members.',
      },
    },
    filter: false,
    print: true,
    viewColumns: false,

    selectableRows: "none",
    elevation: 1,

    downloadOptions: {
      filename: "team_members.csv",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    rowsPerPage: 100,
    rowsPerPageOptions: [5, 10, 20, 50, 100],
    jumpToPage: true,
    responsive: "standard",
    customToolbar: () => (
      <Button
        size="small"
        variant="outlined"
        style={{
          border: "1px solid #eeeeee",
          width: 140,
          height: 28,
          order: -1,
        }}
        onClick={() => setBulkDeleteOpen(true)}
      >
        <span style={{ fontSize: 11, whiteSpace: "nowrap" }}>
          Remove Team Members
        </span>
      </Button>
    ),
  };

  options.sortOrder = {
    name: "joined",
    direction: "desc",
  };

  options.customSearchRender = (searchText, onSearch, onHide) => (
    <CustomSearchBarForTables
      searchText={searchText}
      onSearch={onSearch}
      onHide={onHide}
    />
  );

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const tableData = team.map((member) => ({
    id: member.team_members[0].team_member_id,
    firstName: `${member.first_name} ${member.last_name}`,
    lastName: member.last_name,
    joined: new Date(member.last_team_member_creation_date).toISOString(),
    roles: member.user_id,
    timeSinceLast: member.last_submission_date
      ? new Date(member.last_submission_date).toISOString()
      : null,
    locations: member.team_members[0].location_name,
    hasMultipleTeamMembers:
      member.team_members.length > 1 ? member.team_members : false,
    email: member.email,
    locationId: member?.team_members[0]?.location_id,
  }));

  // console.log(tableData);

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        download: false,
      },
    },
    {
      name: "firstName",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const { rowData } = tableMeta;

          if (Boolean(rowData[8])) {
            return (
              <MultipleLocationPopperV2 name={value} teamMembers={rowData[8]} />
            );
          }

          return (
            <StyledLink
              to={`/teammembers/detail/${tableMeta.rowData[0]}`}
              value={value}
              CustomIcon={User}
            />
          );
        },
      },
    },
    {
      name: "lastName",
      label: "Last Name",
      options: {
        filter: true,
        sort: true,
        download: false,
        display: false,
        print: true,
      },
    },
    {
      name: "locations",
      label: "Location",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const { rowData } = tableMeta;

          if (rowData[8]) {
            return (
              <span style={{ fontSize: 10, color: "darkgrey" }}>
                *user has multiple locations*
              </span>
            );
          }

          const locationId = rowData[10];

          return (
            <ReportLink to={`/locations/detail/${locationId}`}>
              {value}
            </ReportLink>
          );
        },
      },
    },
    {
      name: "joined",
      label: "Date Joined",
      options: {
        filter: true,
        sort: true,
        print: true,
        download: true,
        customBodyRender: (value) => <DateTooltip date={value} />,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
        sort: false,
        display: true,
        download: true,
        print: true,
        customHeadLabelRender: () => <div />,
        customBodyRender: () => <div />,
      },
    },
    {
      name: "timeSinceLast",
      label: "Last Submission",
      options: {
        filter: true,
        sort: true,
        print: true,
        download: true,
        customBodyRender: (value) => {
          const cleanValue = value
            ? formatDistanceToNow(new Date(value), { addSuffix: true })
            : "No Submissions";
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              {getDifferenceInDaysIndicator(value ? new Date(value) : null)}
              <span>{cleanValue}</span>
            </div>
          );
        },
      },
    },
    {
      name: "roles",
      label: "Roles",
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRender: (_, tableMeta) => {
          const { rowData } = tableMeta;
          return <RolesTooltip id={rowData[0]} name={rowData[1]} />;
        },
      },
    },
    {
      name: "hasMultipleTeamMembers",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
        download: false,
        print: false,
      },
    },
    {
      name: "delete",
      label: "Delete",
      options: {
        display: isManager && !readOnly,
        sort: false,
        download: false,
        print: false,
        customBodyRender: (_, tableMeta) => (
          <DeleteButtonForTables
            disabled={readOnly}
            color="default"
            size="medium"
            handleClick={() => {
              const { rowData } = tableMeta;
              setDeleteInfo({
                id: rowData[0],
                name: rowData[1],
              });
            }}
          />
        ),
      },
    },
    {
      name: "locationId",
      label: " ",
      options: {
        filter: true,
        sort: false,
        display: false,
        download: false,
        print: false,
        empty: true,
        customBodyRenderLite: () => <div style={{ width: 1 }} />,
        customHeadLabelRender: () => <div style={{ width: 1 }} />,
      },
    },
  ];

  return (
    <>
      <CustomDatatableV2
        title={
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <TableTitle title="Team Member List" Icon={List} />
          </div>
        }
        data={tableData}
        columns={columns}
        options={options}
        components={{
          icons: CustomDataTableIcons,
        }}
      />
      <Modal
        open={Boolean(deleteInfo)}
        onClose={() => setDeleteInfo(null)}
        aria-labelledby="Delete Team Member Modal"
        aria-describedby="Modal to Delete Team member"
      >
        <div>
          <ConfirmModalSmall
            title="Delete this team member?"
            name={deleteInfo?.name}
            handleConfirm={handleDeleteSingleTeamMember}
          />
        </div>
      </Modal>
      <Modal
        open={bulkDeleteOpen}
        onClose={() => setBulkDeleteOpen(false)}
        aria-labelledby="Bulk Delete Team Member Modal"
        aria-describedby="Modal to Delete Team members in bulk"
      >
        <div>
          <BulkDelete
            team={team}
            mutate={mutate}
            setSnackbarText={setSnackbarText}
            handleClose={() => setBulkDeleteOpen(false)}
          />
        </div>
      </Modal>
      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};

export default TeamDataTable;

TeamDataTable.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  mutate: PropTypes.func.isRequired,
  team: PropTypes.array.isRequired,
};
