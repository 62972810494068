/* eslint-disable */
import { isWithinInterval } from "date-fns";
import React, { useState } from "react";
import { MessageSquare } from "react-feather";
import styled from "styled-components/macro";
import DateRangeSelector from "../../../../../components/DateRangeSelector";
import FeedbackDisliked from "../../../../../components/Feedback/FeedbackDisliked";
import FeedbackLiked from "../../../../../components/Feedback/FeedbackLiked";
import FeedbackTotals from "../../../../../components/Feedback/FeedbackTotals";
import CustomDataTableIcons from "../../../../../components/Table/CustomDataTableIcons";
import TableTitle from "../../../../../components/Table/TableTitle";
import ErrorBlockWithPermissions from "../../../../../components/UI/ErrorBlockWithPermissions";
import {
  fixIncomingDate,
  makeDefaultTableOptions,
} from "../../../../../storage/helpers";
import useRetailFeedback from "../../../../../swr/hooks/Feedback/useRetailFeedback";
import { CustomDatatableV2, ReportLink } from "../../../styles";
import Loader from "../../vendorReports/Loader";

const options = makeDefaultTableOptions("retail_feedback_report");
options.sortOrder = {
  name: "date",
  direction: "desc",
};
options.rowsPerPage = 10;

const ContainTextDiv = styled.div`
  width: fit-content;
  max-width: 300px;
`;

const getFilteredData = (data, startDate, endDate, filterActive) => {
  if (!filterActive || !startDate || !endDate) return data;

  const filtered = data.filter((req) =>
    isWithinInterval(new Date(req.created), {
      start: startDate,
      end: endDate,
    })
  );

  return filtered;
};

const FeedbackReport = () => {
  const { isLoading, data, isError } = useRetailFeedback();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filterActive, setFilterActive] = useState(false);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <ErrorBlockWithPermissions errorObj={isError} />;
  }

  const getTableData = (feedbackArray, isLoading) => {
    if (isLoading) return [];

    return feedbackArray.map((feedback) => ({
      id: feedback.quiz_id,
      date: new Date(feedback.created).toISOString().split("T")[0],
      quiz: feedback.quiz_name,
      text: feedback.text,
      user: `${feedback.user.first_name} ${feedback.user.last_name}`,
      liked: feedback.liked ? "Liked" : "Disliked",
    }));
  };

  const filteredData = getFilteredData(data, startDate, endDate, filterActive);
  const tableData = getTableData(filteredData, isLoading);

  const numDisliked = filteredData.filter((f) => !f.liked).length;
  const numLiked = filteredData.filter((f) => f.liked).length;

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        download: false,
        print: false,
      },
    },
    {
      name: "quiz",
      label: "Module",
      options: {
        filter: true,
        sort: true,

        customBodyRender: (value, tableMeta) => {
          return (
            <ContainTextDiv>
              <ReportLink to={`/modules/progress/${tableMeta?.rowData[0]}`}>
                {value}
              </ReportLink>
            </ContainTextDiv>
          );
        },
      },
    },
    {
      name: "date",
      label: "Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <span>{fixIncomingDate(value).toDateString()}</span>
        ),
      },
    },
    {
      name: "user",
      label: "User Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "liked",
      label: "Feedback",
      options: {
        customBodyRender: (value) =>
          value === "Liked" ? <FeedbackLiked /> : <FeedbackDisliked />,
      },
    },
    {
      name: "text",
      label: "Message",
      options: {
        customBodyRender: (value) => <ContainTextDiv>{value}</ContainTextDiv>,
      },
    },
  ];

  return (
    <>
      <div style={{ height: 12 }} />

      <DateRangeSelector
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        filterActive={filterActive}
        setFilterActive={setFilterActive}
      />
      <div style={{ height: 12 }} />
      <CustomDatatableV2
        data={tableData}
        columns={columns}
        options={options}
        title={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 12,
              flexGrow: 1,
            }}
          >
            <TableTitle title="Feedback Report" Icon={MessageSquare} />
            <FeedbackTotals numLiked={numLiked} numDisliked={numDisliked} />
          </div>
        }
        components={{
          icons: CustomDataTableIcons,
        }}
      />
    </>
  );
};

export default FeedbackReport;
