/* eslint-disable */
/* eslint-disable no-nested-ternary */
import { MenuItem, Tooltip, useMediaQuery } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Book } from "react-feather";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components/macro";
import NoImage from "../../../../../assets/images/empty-small.jpg";
import CustomDataTableIcons from "../../../../../components/Table/CustomDataTableIcons";
import DateTooltip from "../../../../../components/Table/DateTooltip";
import TableTitle from "../../../../../components/Table/TableTitle";
import {
  getSrcTwo,
  makeDefaultTableOptions,
} from "../../../../../storage/helpers";
import useModuleProgress from "../../../../../swr/hooks/RetailReports/useModuleProgress";
import {
  CustomChip,
  CustomDatatableV2,
  MarketplaceChip,
  ShowState,
  StyledLink,
  TextField,
} from "../../../styles";
import ProgressBar from "../../reuseableComponents/ProgressBar";
import ShowModuleFeedback from "../../reuseableComponents/ShowModuleFeedback";
import { retOutstanding, retProgress } from "../../utils/utils";

const ORIGINS = {
  MARKETPLACE: "Marketplace",
  CUSTOM: "Custom",
  ALL: "All",
};

const STATES = {
  ALL: "All",
  LIVE: "Live",
  CLOSED: "Closed",
};

const FlexDivImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  img {
    margin-right: 8px;
    height: 46px;
    width: 46px;
    object-fit: contain;
    border-radius: 8px;
    border: 1px solid #eeeeee;
  }
`;

const getAvatar = (fromMarketplace) => {
  if (fromMarketplace) {
    return (
      <Tooltip title="Module is from marketplace">
        <MarketplaceChip>
          <span>Marketplace</span>
        </MarketplaceChip>
      </Tooltip>
    );
  }

  return (
    <Tooltip title="Custom module built by your team">
      <CustomChip>
        <span>Custom</span>
      </CustomChip>
    </Tooltip>
  );
};

const checkLength = (label) => {
  if (label.length < 31) return label;
  return `${label.substring(0, 30)}...`;
};

const getState = (value) => {
  if (value === 3) return "Live";

  if (value === 4) return "Closed";

  return value;
};

const getTableData = (modules, filterOrigin, filterModuleState, progress) => {
  const modsFilteredForOrigin =
    filterOrigin === ORIGINS.ALL
      ? modules
      : filterOrigin === ORIGINS.CUSTOM
      ? modules.filter((mod) => !mod.marketplace_parent)
      : modules.filter((mod) => mod.marketplace_parent);

  const modsFilteredForBoth =
    filterModuleState === STATES.ALL
      ? modsFilteredForOrigin
      : filterModuleState === STATES.LIVE
      ? modsFilteredForOrigin.filter((mod) => mod.state === "3")
      : modsFilteredForOrigin.filter((mod) => mod.state !== "3");

  return modsFilteredForBoth.map((mod) => {
    return {
      id: mod.id,
      name: mod.name,
      state: getState(Number(mod.state)),
      created: new Date(mod.created).toISOString(),
      marketplaceModule: mod.marketplace_parent ? "Marketplace" : "Custom",
      startDate: mod.start_date ? mod.start_date : null,
      completions: mod.completions,
      feedbackDifferential: Boolean(mod.feedback)
        ? mod?.feedback?.positive - mod?.feedback?.negative
        : 0,
      feedback: {
        positive: mod?.feedback?.positive,
        negative: mod?.feedback?.negative,
      },
      outstanding: retOutstanding(mod.id, progress),
      progress: retProgress(mod.id, progress),
      image: mod.image,
    };
  });
};

const ModulesTable = ({ modules }) => {
  const { progress, isError, isLoading } = useModuleProgress();
  const [filterOrigin, setFilterOrigin] = useState(ORIGINS.ALL);
  const [filterModuleState, setFilterModuleState] = useState(STATES.ALL);
  const tableData = getTableData(
    modules,
    filterOrigin,
    filterModuleState,
    progress
  );
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const options = makeDefaultTableOptions("modules-report");
  options.rowsPerPage = 50;
  options.sortOrder = {
    name: "created",
    direction: "desc",
  };
  options.textLabels = {
    body: {
      noMatch:
        "No modules in queue for your team. Create/Deploy a module to populate this table.",
    },
  };

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        download: false,
      },
    },
    {
      name: "name",
      label: "Module Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <FlexDivImage>
            <LazyLoadImage
              src={getSrcTwo(tableMeta.rowData[11])}
              effect="opacity"
              onError={(event) => (event.target.src = NoImage)}
            />
            <StyledLink
              to={`/modules/progress/${tableMeta.rowData[0]}`}
              value={checkLength(value)}
            />
          </FlexDivImage>
        ),
      },
    },
    {
      name: "created",
      label: "created",
      options: {
        display: false,
        downoad: true,
        customBodyRender: (value) => (
          <DateTooltip date={value} />
          // <span>{fixIncomingDate(value.split("T")[0]).toDateString()}</span>
        ),
      },
    },
    {
      name: "marketplaceModule",
      label: "Origin",
      options: {
        filter: true,
        sort: true,
        display: false,
        customBodyRender: (value) => <>{getAvatar(value === "Marketplace")}</>,
      },
    },
    {
      name: "startDate",
      label: "Start Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <DateTooltip date={value} />

          // <span>{value ? fixIncomingDate(value).toDateString() : "None"}</span>
        ),
      },
    },
    {
      name: "completions",
      label: "Completions",
      options: {
        filter: true,
        sort: true,
        download: true,
      },
    },

    {
      name: "outstanding",
      label: "Outstanding",
      options: {
        filter: true,
        sort: true,
        download: true,
        display: false,
      },
    },
    {
      name: "feedbackDifferential",
      label: "Feedback",
      options: {
        hint: "Feedback left by users in the app",
        print: false,
        download: false,
        customBodyRender: (_, tableMeta) => (
          <ShowModuleFeedback feedback={tableMeta.rowData[8]} />
        ),
      },
    },
    {
      name: "feedback",
      label: "",
      options: {
        print: false,
        download: false,
        display: false,
      },
    },
    {
      name: "state",
      label: "State",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <ShowState state={value}>
            <span>{value}</span>
          </ShowState>
        ),
      },
    },
    {
      name: "progress",
      label: "Progress",
      options: {
        filter: false,
        sort: true,
        display: true,
        customBodyRender: (_, tableMeta) => (
          <ProgressBar
            isError={isError}
            isLoading={isLoading}
            progress={progress}
            id={tableMeta.rowData[0]}
          />
        ),
      },
    },
    {
      name: "image",
      label: "image",
      options: {
        sort: false,
        filter: false,
        download: false,
        display: false,
        print: false,
      },
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "100%",
          marginTop: 22,
          marginBottom: 12,
        }}
      >
        <div style={{ width: 180, marginRight: 8 }}>
          <TextField
            select
            label="Module Type"
            elevation={1}
            variant="outlined"
            fullWidth
            size="small"
            value={filterOrigin}
            onChange={(e) => setFilterOrigin(e.target.value)}
            style={{
              backgroundColor: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <MenuItem
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              value={ORIGINS.ALL}
            >
              All
            </MenuItem>
            <MenuItem
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              value={ORIGINS.CUSTOM}
            >
              <CustomChip>
                <span>Custom</span>
              </CustomChip>
            </MenuItem>
            <MenuItem
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              value={ORIGINS.MARKETPLACE}
            >
              <MarketplaceChip>
                <span>Marketplace</span>
              </MarketplaceChip>
            </MenuItem>
          </TextField>
        </div>
        <div style={{ width: 180 }}>
          <TextField
            select
            label="Module State"
            elevation={1}
            variant="outlined"
            size="small"
            fullWidth
            value={filterModuleState}
            onChange={(e) => setFilterModuleState(e.target.value)}
            style={{
              backgroundColor: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <MenuItem
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              value={STATES.ALL}
            >
              All
            </MenuItem>
            <MenuItem
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              value={STATES.LIVE}
            >
              <ShowState state="Live">
                <span>Live</span>
              </ShowState>
            </MenuItem>
            <MenuItem
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              value={STATES.CLOSED}
            >
              <ShowState state="Closed">
                <span>Closed</span>
              </ShowState>
            </MenuItem>
          </TextField>
        </div>
      </div>
      <CustomDatatableV2
        title={<TableTitle title="Modules Report" Icon={Book} />}
        data={tableData}
        options={options}
        columns={columns}
        components={{
          icons: CustomDataTableIcons,
        }}
      />
    </>
  );
};

export default ModulesTable;

ModulesTable.propTypes = {
  modules: PropTypes.array.isRequired,
};
