/* eslint-disable */
import { Modal } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Plus, Repeat, UploadCloud } from "react-feather";
import styled from "styled-components/macro";
import { Button } from "../../styles";
import UploadImageModal from "./UploadImageModal";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 0.5;

  border-radius: 6px;
`;

const AddModuleImage = ({ handleImageSave, setSnackbarText, details }) => {
  const [uploadImageModalOpen, setUploadImageModalOpen] = useState(false);

  const hasImage = Boolean(details.image);
  // const hasImage = false;

  return (
    <Wrapper>
      {hasImage ? (
        <div
          style={{
            // background: "#eeeeee50",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            minHeight: 390,
            flexGrow: 1,
            borderRadius: 4,
          }}
        >
          <img
            style={{
              width: 260,
              height: 260,
              borderRadius: 6,
              background: "white",
              objectFit: "contain",
              border: "1px solid lightgray",
            }}
            src={details?.image}
          />
          <Button
            variant="outlined"
            style={{ marginTop: 8, background: "white" }}
            onClick={() => {
              setUploadImageModalOpen((p) => !p);
            }}
            startIcon={<UploadCloud height={14} width={14} />}
          >
            <span>New Image</span>
          </Button>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            minHeight: 350,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexGrow: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              style={{
                border: "1px solid lightgray",

                color: "white",
                borderRadius: 4,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
              onClick={() => setUploadImageModalOpen(true)}
            >
              {/* <span>No Module Image Yet</span> */}
              <Plus color="#337ab7" height={16} width={16} />
              <span style={{ color: "black", marginLeft: 4, fontSize: 12 }}>
                Add Image
              </span>
            </Button>
          </div>
        </div>
      )}
      <Modal
        open={uploadImageModalOpen}
        onClose={() => setUploadImageModalOpen(false)}
      >
        <div>
          <UploadImageModal
            setSnackbarText={setSnackbarText}
            handleImageSave={handleImageSave}
            setUploadImageModalOpen={setUploadImageModalOpen}
          />
        </div>
      </Modal>
    </Wrapper>
  );
};

export default AddModuleImage;

AddModuleImage.propTypes = {
  handleImageSave: PropTypes.func.isRequired,
  formSubmitting: PropTypes.bool.isRequired,
  afterCrop: PropTypes.string,
  setAfterCrop: PropTypes.func.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
};

AddModuleImage.defaultProps = {
  afterCrop: null,
};
