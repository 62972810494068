/* eslint-disable */
/* eslint-disable max-len */
import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components/macro";
import LoaderWrapper from "../../../../components/Loaders/LoaderWrapper";
import OnLoadError from "../../../../components/UI/OnLoadError";
import PrivateHeaderRetailDashboard from "../../../../components/PrivateHeaderRetailDashboard";
import { ENGAGEMENT_TYPES } from "../../../../storage/constants";
import useHome from "../../../../swr/hooks/Home/useHome";
import EngagementTypeSelect from "../../reports/reuseableComponents/EngagementTypeSelect";
import HeroLayout from "../layouts/HeroLayout";
import RightConsoleLayout from "../layouts/RightConsoleLayout";
import HomeEngagementChart from "../reuseableComponents/HomeEngagementChart";
import NewMembers from "../reuseableComponents/NewMembers";
import AllSubmissionsTable from "./AllSubmissionsTable";
import AlertAccordion from "./AlertAccordion";
import RightSide from "./RightSide";
import SubmissionBreakdownTable from "./SubmissionBreakdownTable";
import SnackbarWrapper from "../../../../components/SnackbarWrapper";
import StatBoxes from "./StatBoxes";

const Wrapper = styled.div`
  height: calc(100vh - 64px);
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const PaddingWrap = styled.div`
  padding: 16px;
`;

const getFilteredSubmissions = (submissions, subType) => {
  if (subType === ENGAGEMENT_TYPES.ASSIGNED) {
    return submissions.filter((sub) => sub.assigned);
  }
  if (subType === ENGAGEMENT_TYPES.EXTRACREDIT) {
    return submissions.filter((sub) => !sub.assigned);
  }
  return submissions;
};

const RetailHomepage = ({ config }) => {
  const { isLoading, data, isError } = useHome();

  const [submissionsType, setSubmissionType] = useState(ENGAGEMENT_TYPES.ALL);
  const [snackbarText, setSnackbarText] = useState("");

  if (isLoading) {
    return (
      <>
        <LoaderWrapper text="Home" />
      </>
    );
  }

  if (isError) {
    return <OnLoadError />;
  }

  const { current_company: currentCompany } = config;

  const { company_data: companyData, submissions_data: submissionsData } = data;
  const filteredSubmissions = getFilteredSubmissions(
    submissionsData?.recent_submissions,
    submissionsType
  );

  return (
    <>
      <Wrapper>
        <HeroLayout>
          <PrivateHeaderRetailDashboard header={currentCompany?.name}>
            <div>
              <EngagementTypeSelect
                engagementType={submissionsType}
                setEngagementType={setSubmissionType}
              />
            </div>
          </PrivateHeaderRetailDashboard>

          <PaddingWrap>
            <StatBoxes
              submissionsType={submissionsType}
              submissionsData={submissionsData}
              companyData={companyData}
            />
          </PaddingWrap>
          <AlertAccordion
            snackbarText={snackbarText}
            setSnackbarText={setSnackbarText}
          />
          <PaddingWrap>
            <HomeEngagementChart
              submissions={filteredSubmissions}
              engagementType={submissionsType}
            />
          </PaddingWrap>
          <PaddingWrap>
            <SubmissionBreakdownTable
              submissions={filteredSubmissions}
              submissionsType={submissionsType}
            />
          </PaddingWrap>
          <PaddingWrap>
            <AllSubmissionsTable
              submissions={filteredSubmissions}
              engagementType={submissionsType}
            />
          </PaddingWrap>
          {companyData?.new_members?.length > 0 && (
            <>
              <PaddingWrap>
                <NewMembers
                  members={companyData?.new_members}
                  isRetailHomepage
                />
              </PaddingWrap>
            </>
          )}
        </HeroLayout>
        <RightConsoleLayout>
          <RightSide data={data} company={currentCompany} config={config} />
        </RightConsoleLayout>
      </Wrapper>
      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};

export default RetailHomepage;

RetailHomepage.propTypes = {
  config: PropTypes.object.isRequired,
};
